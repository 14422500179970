import SearchField from './SearchField';

type Props = {
  noPadding?: boolean;
}

const PopsyncLogo = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.019 13.1901C27.3531 13.1901 28.4345 12.1087 28.4345 10.7746C28.4345 9.44059 27.3531 8.35913 26.019 8.35913C24.685 8.35913 23.6035 9.44059 23.6035 10.7746C23.6035 12.1087 24.685 13.1901 26.019 13.1901Z" fill="#99C6FF" />
    <path d="M22.2212 4.43625C23.3885 3.61565 24.834 3.29238 26.2397 3.53756C27.6454 3.78274 28.8961 4.57628 29.7167 5.74361C30.5373 6.91095 30.8606 8.35646 30.6154 9.76214C30.3702 11.1678 29.5767 12.4185 28.4094 13.2391L22.2212 4.43625Z" fill="#66AAFF" />
    <path d="M21.0014 7.12496H14.863C13.8547 7.12167 12.8888 6.7188 12.177 6.00464C11.4651 5.29048 11.0654 4.32327 11.0654 3.31494H20.6029C21.1033 3.31494 21.5987 3.41349 22.061 3.60496C22.5232 3.79643 22.9432 4.07708 23.297 4.43087C23.6508 4.78466 23.9315 5.20468 24.1229 5.66693C24.3144 6.12918 24.4129 6.62462 24.4129 7.12496H21.0014Z" fill="#99C6FF" />
    <path d="M22.2211 4.81002H16.0827C15.0744 4.80672 14.1085 4.40386 13.3967 3.6897C12.6849 2.97554 12.2852 2.00833 12.2852 1L21.8351 1C22.8434 1.0033 23.8093 1.40616 24.5212 2.12032C25.233 2.83448 25.6327 3.80169 25.6327 4.81002H22.2211Z" fill="#66AAFF" />
    <path d="M20.2042 3.45264C18.6852 3.45264 17.2284 4.05607 16.1543 5.13018C15.0802 6.20429 14.4767 7.66109 14.4767 9.18012V19.3775L8.36328 31.2185C10.9157 32.5258 14.4643 30.7329 16.2821 27.2092C16.4067 26.9727 16.5187 26.7236 16.6183 26.4871H17.2907C18.3545 26.4871 19.408 26.2773 20.3907 25.8698C21.3734 25.4623 22.2661 24.8651 23.0178 24.1122C23.7695 23.3594 24.3654 22.4657 24.7714 21.4824C25.1774 20.499 25.3855 19.4453 25.3839 18.3814V3.45264H20.2042Z" fill="#0072FF" />
    <path d="M23.1555 5.2583H25.3842V14.7708H23.529C22.218 14.7708 20.9607 14.2501 20.0337 13.3231C19.1067 12.3961 18.5859 11.1388 18.5859 9.82779C18.5859 8.61587 19.0674 7.45359 19.9243 6.59664C20.7813 5.73968 21.9436 5.2583 23.1555 5.2583Z" fill="#E6F1FF" />
    <path d="M23.3298 8.57052C23.2641 8.878 23.0945 9.1535 22.8497 9.35075C22.6048 9.548 22.2995 9.65499 21.9851 9.65376C21.7395 9.65507 21.498 9.59089 21.2854 9.46784C21.0728 9.34479 20.8969 9.16731 20.7757 8.95367C20.6545 8.74003 20.5924 8.49796 20.5958 8.25236C20.5993 8.00676 20.6681 7.76652 20.7952 7.55636C20.9224 7.3462 21.1032 7.17372 21.3192 7.05667C21.5351 6.93962 21.7783 6.88223 22.0238 6.89042C22.2693 6.8986 22.5082 6.97206 22.7158 7.10322C22.9235 7.23439 23.0925 7.41853 23.2053 7.63669C23.1114 7.60465 23.0105 7.59933 22.9137 7.62132C22.817 7.6433 22.7283 7.69173 22.6575 7.7612C22.6078 7.80989 22.5683 7.86801 22.5413 7.93215C22.5144 7.99629 22.5005 8.06516 22.5005 8.13473C22.5005 8.20431 22.5144 8.27318 22.5413 8.33732C22.5683 8.40146 22.6078 8.45957 22.6575 8.50827C22.7453 8.59427 22.8601 8.64741 22.9826 8.65875C23.105 8.67009 23.2276 8.63893 23.3298 8.57052Z" fill="#0072FF" />
    <path d="M21.2875 17.3855C21.2991 16.783 21.1904 16.1843 20.9679 15.6243C20.7453 15.0643 20.4133 14.5544 19.9913 14.1242C19.5693 13.6941 19.0657 13.3524 18.5101 13.1192C17.9545 12.8861 17.3579 12.766 16.7553 12.7661H2.00084C1.98925 13.3686 2.09792 13.9673 2.32049 14.5273C2.54306 15.0873 2.87506 15.5972 3.29707 16.0273C3.71908 16.4575 4.22263 16.7991 4.77826 17.0323C5.33389 17.2655 5.93044 17.3856 6.53302 17.3855H6.39606V17.5722C6.39606 18.7478 6.86306 19.8752 7.69433 20.7065C8.5256 21.5378 9.65304 22.0048 10.8286 22.0048H21.2875V17.3855Z" fill="#0072FF" />
    <path d="M21.2874 17.3855C21.299 16.783 21.1903 16.1843 20.9677 15.6243C20.7452 15.0643 20.4132 14.5544 19.9911 14.1242C19.5691 13.6941 19.0656 13.3524 18.51 13.1192C17.9543 12.8861 17.3578 12.766 16.7552 12.7661H11.5382C11.5249 13.368 11.632 13.9664 11.8532 14.5263C12.0744 15.0863 12.4052 15.5963 12.8263 16.0266C13.2473 16.4569 13.75 16.7988 14.305 17.0321C14.8599 17.2655 15.4559 17.3856 16.0579 17.3855H15.9334V17.5722C15.9334 18.7478 16.4004 19.8752 17.2317 20.7065C18.063 21.5378 19.1904 22.0048 20.366 22.0048H21.2874V17.3855Z" fill="#66AAFF" />
    <path d="M28.8949 23.8353C28.6496 23.6113 28.3626 23.4378 28.0503 23.3247C27.738 23.2116 27.4064 23.1611 27.0746 23.1762C26.7428 23.1912 26.4172 23.2714 26.1164 23.4123C25.8155 23.5532 25.5454 23.7519 25.3214 23.9972C25.0974 24.2425 24.9239 24.5294 24.8108 24.8418C24.6977 25.1541 24.6472 25.4856 24.6623 25.8174C24.6773 26.1493 24.7575 26.4749 24.8984 26.7757C25.0393 27.0765 25.238 27.3466 25.4833 27.5706L28.8949 23.8353Z" fill="#99C6FF" />
    <path d="M27.7 25.1427C27.2047 24.6903 26.5499 24.4532 25.8798 24.4836C25.2096 24.5139 24.579 24.8092 24.1266 25.3046C23.6742 25.7999 23.4371 26.4547 23.4674 27.1248C23.4978 27.795 23.7931 28.4256 24.2885 28.878L27.7 25.1427Z" fill="#66AAFF" />
  </svg>

);

export default ({ noPadding = false }: Props) => {
  const triggerSearch = (searchString: string | undefined) => {
    if (searchString) {
      window.open(`https://popsync.io/?q=${searchString}&skip=1&src=bluescape.com`, '_blank');
    } else {
      window.open('https://popsync.io/?src=bluescape.com', '_blank');
    }
  };

  return (
    <SearchField formPrefix="Popsync" onSubmit={triggerSearch} noPadding={noPadding} icon={<PopsyncLogo />} placeholder="Find images" />
  );
};
