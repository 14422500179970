import { useRef } from 'react';
import styled from 'styled-components';

export const MagnifyingIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5 11C5 7.68629 7.68629 5 11 5C14.3137 5 17 7.68629 17 11C17 14.3137 14.3137 17 11 17C7.68629 17 5 14.3137 5 11ZM11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19C12.9036 19 14.652 18.3351 16.0255 17.2249C16.0661 17.4016 16.1552 17.5694 16.2929 17.7071L19.2929 20.7071C19.6834 21.0976 20.3166 21.0976 20.7071 20.7071C21.0976 20.3166 21.0976 19.6834 20.7071 19.2929L17.7071 16.2929C17.5694 16.1552 17.4016 16.0661 17.2249 16.0255C18.3351 14.652 19 12.9036 19 11C19 6.58172 15.4183 3 11 3Z" fill="#2E363D" />
  </svg>
);

type ContainerProps = {
  noPadding: boolean
}

const Container = styled.div<ContainerProps>`
  max-width: 320px;
  width: 100%;
  padding:  ${(props) => (props.noPadding ? '0' : '12px 0')};
  position: relative;
  box-sizing: border-box;
`;

const LogoContainer = styled.div`
  position: absolute;
  left: 12px;
  top: 50%;
  height: 32px;
  width: 32px;
  transform: translatey(-50%);
`;

const MagnifyingGlassContainer = styled.button`
  position: absolute;
  right: 8px;
  top: 50%;
  height: 40px;
  width: 40px;
  transform: translateY(-50%);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 150ms;
  pointer-events: all;
  border: none;
  background-color: transparent;

  &:hover {
    background-color: var(--color-bluescape-v10);
  }
`;

const Input = styled.input<{ hasIcon?: boolean }>`
  background: #FFFFFF;
  border: 1px solid #D3D4D5;
  width: 100%;
  border-radius: 12px;
  max-width: 100%;
  height: 48px;
  padding: 0 48px;
  font-size: 18px;
  box-sizing: border-box;
  padding-left: ${(props) => (props.hasIcon ? '52px' : '16px')};

  ::placeholder { 
    color: var(--color-charcoal-v40);
  }

  &:focus::placeholder {
    color: transparent;
  }
`;

export type SearchBarProps = {
  formPrefix: string;
  onSubmit: (content: string) => void;
  noPadding?: boolean;
  placeholder?: string;
  value?: string;
  icon?: React.ReactNode;
};

const SearchField = ({
  onSubmit, noPadding = false,
  icon, placeholder, value, formPrefix,
}: SearchBarProps) => {
  const fieldRef = useRef<HTMLInputElement>(null);

  const handleFormSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    onSubmit(fieldRef.current?.value || '');
  };

  return (
    <Container noPadding={noPadding}>
      <form id={`${formPrefix}Form`} onSubmit={handleFormSubmit}>
        <Input id={`${formPrefix}Input`} ref={fieldRef} placeholder={placeholder} defaultValue={value} hasIcon={!!icon} />
        <MagnifyingGlassContainer id={`${formPrefix}SearchButton`}>
          <MagnifyingIcon />
        </MagnifyingGlassContainer>
        {icon && (
          <LogoContainer>
            {icon}
          </LogoContainer>
        )}
      </form>
    </Container>
  );
};

export default SearchField;
