import React from 'react';
import styled from 'styled-components';

const Quotes = () => (
  <svg width="44" height="35" viewBox="0 0 44 35" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.1137 34.9412C16.5782 34.9412 20.019 30.1433 20.019 25.2411C20.019 20.3389 16.5782 15.541 9.80095 15.541C11.0521 10.9517 13.346 6.98824 16.5782 2.81616L13.2417 0C7.40284 5.63231 0 16.4797 0 24.0938C0 30.1433 3.64929 34.9412 10.1137 34.9412ZM34.0948 34.9412C40.5592 34.9412 44 30.1433 44 25.2411C44 20.3389 40.5592 15.541 33.782 15.541C35.0332 10.9517 37.327 6.98824 40.5592 2.81616L37.2228 0C31.3839 5.63231 23.981 16.4797 23.981 24.0938C23.981 30.1433 27.6303 34.9412 34.0948 34.9412Z" />
  </svg>
);

const BlockQuoteWrapper = styled.blockquote.attrs({ className: 'theme-bluescape-pale' })`
    background-color: var(--theme-background);
    margin: 0;
    padding: 32px;
    font-size: 24px;
    line-height: 1.2;
    border-radius: 16px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 32px;
    margin-top: 32px;
    gap: 24px;

    svg {
      min-width: 44px;
      fill: var(--theme-simple-quote-color);
    }

    p:last-child {
      margin-bottom: 0;
    }

    p {
      margin: 12px 0;
    }

    p:first-child {
      margin-top: 0;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
      flex-direction: column;
    }
`;
export default ({ children }: { children: React.ReactNode }) => (
  <BlockQuoteWrapper>
    <Quotes />
    <div>
      {children}
    </div>
  </BlockQuoteWrapper>
);
