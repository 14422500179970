import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, Document } from '@contentful/rich-text-types';
import styled from 'styled-components';
import React from 'react';
import {
  CFLink, CFRichTextLinks, EmbeddedVideo, WithId, WithTypename,
} from '../../lib/types';
import Button from '../components/Button';
import BlockQuote from '../components/BlockQuote';
import { AutoLink } from '../components/Link';
import PopsyncInput from '../components/PopsyncInput';
import VideoInlinePlayer from '../components/InlineVideoPlayer';

export type ContentfulButton = WithId<{
  label?: string
  url: string
  title?: string
  __typename?: 'Button'
}>;

export type CFDocument = Parameters<typeof documentToReactComponents>[0];

export type ContentAlignment = WithId<{
  alignment: 'Left' | 'Center' | 'Right'
  __typename?: 'ContentAlignment'
  content: {
    json: Document
    links: CFRichTextLinks
  }
}>;

export type ContentfulAsset = WithTypename<
  ContentfulButton | WithId<CFLink> | WithId<EmbeddedVideo> | ContentAlignment>;

export type ContentfulNodeType = {
    nodeType: string,
    data: {
        target?: {
            sys: {
                id: string
                type: string,
                linkType: string
            }
        }
    },
    content: any
  };

export const isLink = (asset: ContentfulAsset): asset is WithTypename<WithId<CFLink>> => {
  if ((asset as WithId<CFLink>).title) {
    return true;
  }
  return false;
};

/** Convert rich text to a specific header level,
 *  replaces most block elements with the specified tag */
export const documentToOnlyHeaders = (document: CFDocument, options: {level: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'}) => {
  const { level: L } = options;
  const renderFunc = (_: any, children: React.ReactNode) => <L>{children}</L>;
  return documentToReactComponents(document, {
    renderNode: {
      [BLOCKS.PARAGRAPH]: renderFunc,
      [BLOCKS.HEADING_1]: renderFunc,
      [BLOCKS.HEADING_2]: renderFunc,
      [BLOCKS.HEADING_3]: renderFunc,
      [BLOCKS.HEADING_4]: renderFunc,
      [BLOCKS.HEADING_5]: renderFunc,
      [BLOCKS.HEADING_6]: renderFunc,
    },
  });
};

const POSSIBLE_POPSYNC_ASSETS = new Map<string, string>([
  ['3Hd63tkivUVrIzNKcKHFrL', 'popsync_default.json'],
]);

const LeftAligned = styled.div`
  text-align: left;
`;

const CenterAligned = styled.div`
  text-align: center;
`;

const RightAligned = styled.div`
  text-align: right;
`;

export const getEmbeddedType = (entry: WithTypename<ContentfulAsset>, _: ContentfulNodeType) => {
  if (entry.__typename === 'AnyLink') {
    return <AutoLink {...entry} />;
  }
  if (entry.__typename === 'WistiaVideo') {
    return <VideoInlinePlayer {...entry} />;
  }

  if (entry.__typename === 'ContentAlignment') {
    switch (entry.alignment) {
      case 'Left':
        return <LeftAligned className="leftAlign">{documentToReactComponents(entry.content.json)}</LeftAligned>;
      case 'Center':
        return <CenterAligned className="centerAlign">{documentToReactComponents(entry.content.json)}</CenterAligned>;
      case 'Right':
        return <RightAligned className="rightAlign">{documentToReactComponents(entry.content.json)}</RightAligned>;
      default:
        return null;
    }
  }
  if (entry.__typename === 'Button') {
    return (
      <Button
        href={entry.url}
      >
        {entry.label}
      </Button>
    );
  }
  return null;
};

export const getRenderOption = (content: {
    links?: {
      entries: {block?: Array<ContentfulAsset>, inline?: Array<ContentfulAsset>};
      assets: {block: Array<ContentfulAsset>};
    };
  }) => ({
  renderNode: {
    [BLOCKS.QUOTE]: (_: unknown, children: React.ReactNode) => (
      <BlockQuote>
        {children}
      </BlockQuote>
    ),
    [INLINES.EMBEDDED_ENTRY]: (node: ContentfulNodeType) => {
      const entry = content?.links?.entries.inline?.find(
        (contentEntry: ContentfulAsset) => contentEntry.sys.id === node.data.target?.sys.id,
      );
      if (entry) {
        return getEmbeddedType(entry, node);
      }
      return null;
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node: ContentfulNodeType) => {
      const entry = content?.links?.entries.block?.find(
        (contentEntry: ContentfulAsset) => contentEntry.sys.id === node.data.target?.sys.id,
      );
      if (entry) {
        return (
          <div>
            {getEmbeddedType(entry, node)}
          </div>
        );
      }
      return null;
    },
    [BLOCKS.EMBEDDED_ASSET]: (node: ContentfulNodeType) => {
      const asset = content?.links?.assets.block.find(
        (contentAsset: ContentfulAsset) => contentAsset.sys.id === node.data.target?.sys.id,
      ) as {title: string, url: string, sys: {id: string}};

      if (POSSIBLE_POPSYNC_ASSETS.has(asset.sys.id)) {
        return (
          <PopsyncInput />
        );
      }
      if (asset) {
        return (
          <img
            alt={asset.title}
            src={asset.url}
          />
        );
      }
      return null;
    },
  },
});

export const RichText = styled.div.attrs({ className: 'rich-text' })`
  color: var(--theme-text-color);
  a, .link {
    color: var(--theme-link-color);
    text-decoration: none;
    font-weight: 600;
  }
  ul {
    list-style: none;
    display: grid;
    gap: 12px;
    margin: 0;
    padding: 0 0 0 32px;
  }
  li {
    display: flex;

    p {
      margin: 0;
    }
  }
  li::before {
    content: "»";
    color: var(--theme-detail-color);
    display: inline-block;
    width: 1em;
    margin-left: -1em
  }
  p {
    margin-bottom: 24px;
    margin-top: 0;
  }
  h4 {
    color: var(--color-charcoal-v100)
  }
  .leftAlign {
    clear: both;
    text-align: left;
  }
  .centerAlign {
    clear: both;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .rightAlign {
    clear: both;
    text-align: right;
  }

`;
